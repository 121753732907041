<template>
  <div class="flex  items-center" :class="{ 'flex-col':isDialouge}">
    <MaterialsShowQuantityByStep
      v-if="material.simple_unit_style || !material.unit_information?.length"
      v-model="quantity"
      class="pt-2"
      :material="material"
    />
    <MaterialsShowQuantityByUnit
      v-else
      v-model="quantity"
      :enable-text="true"
      class="pt-2"
      :material="material"
    />
    <div
      v-if="material.store?.cart_visible && material.store?.price_visible"
      class="flex flex-wrap items-center justify-between my-2"
    >
      <v-icon
        icon="fa :fa-solid fa-cart-shopping"
        :size="24"
        class="opacity-90"
      />

      <div class="mx-auto flex items-end bg-text_color p-1.5 rounded-lg">
        <div @click.stop="emit('open-dialouges')">
          <MevcutSubmitButton
            :title="t('add_material', { to: t('cart') })"
            color="w-32 h-7 leading-none pt-2 bg-nav_color"
            text-color="text-text_alt_color"
            type="button"
            :loading="loading"
          />
        </div>
        <div
          v-if="material.store?.price_visible"
          :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
          class="flex-shrink-0 leading-none mx-2 text-lg font-medium text-bg_color"
        >
          <span>{{ totalPrice }}</span>
        </div>

        <div
          v-if="totalPriceWithOutDiscount && material.store?.price_visible"
          class="flex-shrink-0 leading-none line-through font-medium text-bg_color"
          :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
        >
          {{ totalPriceWithOutDiscount }}
        </div>
      </div>
    </div>

    <div
      v-else
      class="mx-auto flex items-end bg-text_color p-1.5 rounded-lg"
      @click.stop="emit('open-dialouges')"
    >
      <MevcutSubmitButton
        :title="t('contact_via_whatsApp', { to: t('cart') })"
        color="w-32 h-7 leading-none pt-2 bg-nav_color"
        text-color="text-text_alt_color"
        type="button"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Material } from '~~/composables/useMenuModel'
const props = defineProps<{
  modelValue: number
  totalPrice: String
  isDialouge?: boolean
  totalPriceWithOutDiscount: String
  loading: boolean
  material: Material
}>()

const emit = defineEmits(['update:modelValue', 'open-dialouges'])

const { t } = useI18n()

const quantity = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  }
})

</script>

<style scoped>

</style>
