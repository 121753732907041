<template>
  <div
    class="px-1 mb-3 cursor-pointer"
    :class="classCard"
    @click="selectMaterial()"
  >
    <div></div>
    <div v-if="openDialougeMaterialShow">
      <MaterialsMaterialDialouge
        v-model="openDialougeMaterialShow"
        :material-id="material.id"
        :first-property-lookup="material.first_property_lookup_value"
      />
    </div>
    <MaterialsDialougeSelectBranchForMaterial
      v-model="openDialougeSelectBranch"
      :branches="material.branches"
      @select-branch="
        material.store?.cart_visible
          ? addItemLocal(undefined, $event)
          : useContactViaWhatsAppForMaterials(material, undefined, $event)
      "
    />
    <div class="relative">
      <NuxtImg
        provider="cacheIpx"
        preset="modified"
        loading="lazy"
        :src="material.image"
        :alt="material.name"
        width="128"
        height="128"
        sizes="md:256px"
        class="rounded-[14px] w-full object-cover"
      />
      <MaterialsCardButtonFavourite
        v-if="!isDomain"
        class="absolute ltr:left-1 rtl:right-1 top-1"
        :material="material"
      />
      <div
        v-if="!isDomain"
        class="absolute ltr:left-2 rtl:right-2 bottom-2 bg-black-900 items-center rounded-lg flex items-center p-0.5"
      >
        <!-- backdrop-blur-sm -->
        <v-icon
          v-for="star in 5"
          :key="`show-material-star-${star}`"
          :icon="
            star <= (material.average_rate || 0)
              ? 'fa: fa-solid fa-star'
              : 'fa: fa-regular fa-star'
          "
          color="yellow"
          class="mx-0.5"
          :size="11"
        />
      </div>
      <div
        v-if="material.is_featured"
        class="absolute ltr:right-0 text-text_alt_color rtl:left-0 top-0 text-xs lg:text-base bg-nav_color ltr:rounded-br-2xl ltr:rounded-tl-2xl rtl:rounded-bl-2xl rtl:rounded-tr-2xl px-1 sm:py-0.5"
      >
        {{ t('featured') }}
      </div>
      <div
        v-if="material.discountRate && storeMaterial?.price_visible"
        class="absolute bottom-7 ltr:right-0 rtl:left-0 px-1 font-medium text-xs lg:text-base bg-nav_color text-text_alt_color"
      >
        <div>{{ t('discount') }} %{{ parseInt(material.discountRate) }}</div>
      </div>
    </div>
    <div class="relative">
      <div
        v-if="loading"
        class="absolute inset-0 bg-button_color bg-opacity-40 rounded-lg"
      >
        <MevcutLoadingIndicator
          :loading="true"
          color="border-nav_color"
          class="text-nav_color h-full"
        />
      </div>
      <MaterialsCardAction
        :material="material"
        @toggle-click-cart="toggleOpenDialouges"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import { useRouteMaterial } from '~/composables/useDomain'
import type { Material } from '~~/composables/useMenuModel'

const props = withDefaults(
  defineProps<{
    materialProps: Material
    classCard?: string
    wishlist?: boolean
    isDelivering?: boolean
    branchIdInitail?: number
  }>(),
  {
    classCard: 'customWidth',
    wishlist: false,
    branchIdInitail: undefined
  }
)

const { materialModel } = useMenuModel()
const material = materialModel(props.materialProps)
if (props.wishlist) {
  material.hasFavoritedByAgent = true
  material.has_favorited = true
}
const toast = useToast()
const { addItem } = useCartStore()
const loading = ref(false)
const openDialougeSelectBranch = ref(false)
const openDialougeMaterialShow = ref(false)
const isDomain = useIsDomain()
const { store } = useDomainState()
const router = useRouter()

const isNotHasInitailBranchId = computed(() => {
  if (useBranchId()) {
    return false
  }
  return props.branchIdInitail === undefined
})
const storeMaterial = computed(() => {
  return isDomain ? store.value : material.store
})
const enableOpenDialougeSelectBranch =
  isNotHasInitailBranchId.value && (material.branches?.length || 0) > 1
const branchId = ref<number | undefined>(useBranchId(props.branchIdInitail))
const { t } = useI18n()
const localePath = useLocalePath()
function selectMaterial() {
  router.push(
    localePath(useRouteMaterial(material, branchId.value, props.isDelivering))
  )
}
function toggleOpenDialouges(event?: Event) {
  if (material.first_property_lookup_value || !material.simple_unit_style) {
    openDialougeMaterialShow.value = true
  } else if (enableOpenDialougeSelectBranch) {
    openDialougeSelectBranch.value = true
  } else if (storeMaterial.value?.cart_visible) {
    addItemLocal(event)
  } else {
    const currentBranch = material.branches?.find(
      (branch) => branch.id === branchId.value
    )
    useContactViaWhatsAppForMaterials(material, undefined, currentBranch)
  }
}

async function addItemLocal(event?: Event, branch?: BranchStore) {
  event?.preventDefault()
  if (!loading.value) {
    loading.value = true

    if (
      await addItem(
        material,
        material.min_quantity,
        undefined,
        branch?.id || branchId.value
      )
    ) {
      toast.success(t('add_done', { to: t('to'), name: t('cart') }), {
        timeout: 1000
      })
    } else {
      toast.error(t('add_failed'), { timeout: 1000 })
    }
    loading.value = false
  }
}
</script>
<style scoped>
.v-icon--size-default.custom-size-star {
  @apply text-[10px]  w-[10px] h-[6px]  !important;
}
.text-overflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.customWidth {
  width: 46vw;
}
@media (min-width: 650px) {
  .customWidth {
    @apply sm:w-1/3  md:w-[13.5rem] md:mr-3.5;
  }
}
</style>
