<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    xml:space="preserve"
  >

    <path
      class="st0"
      d="M87.2,100H12.8C5.7,100,0,94.3,0,87.2V12.8C0,5.7,5.7,0,12.8,0h74.4C94.3,0,100,5.7,100,12.8v74.4C100,94.3,94.3,100,87.2,100z"
    />
    <g>
      <path class="st1" d="M75.9,52.1l-1.7,6.8c-0.8,3.4-3.7,5.8-7.2,6.2l-28,2.8c-4.2,0.4-8-2.4-8.9-6.5l-6.3-29.8H53" />
      <path class="st1" d="M23.9,31.6l-2.7-12.3h-7.1" />
      <path
        class="st1"
        d="M70.1,79.1c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5 C71.6,79.8,70.9,79.1,70.1,79.1"
      />
      <path
        class="st1"
        d="M35.5,79.1c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C37,79.8,36.3,79.1,35.5,79.1"
      />
    </g>
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="76.3045"
        y1="14.1126"
        x2="76.3045"
        y2="44.6"
      >
        <stop offset="0" style="stop-color:#61FD7D" />
        <stop offset="1" style="stop-color:#2BB826" />
      </linearGradient>
      <path
        class="st2"
        d="M87.4,18.6c-3-3-6.9-4.6-11.1-4.6c-8.6,0-15.6,7-15.6,15.6c0,2.7,0.7,5.4,2.1,7.8l-2.2,8.1l8.3-2.2 c2.3,1.2,4.9,1.9,7.5,1.9h0c8.6,0,15.6-7,15.6-15.6C92,25.5,90.4,21.5,87.4,18.6z M84.7,35.7c-0.4,1-2,1.9-2.9,2 c-0.7,0.1-1.7,0.2-2.7-0.2c-0.6-0.2-1.4-0.5-2.4-0.9c-4.2-1.8-7-6.1-7.2-6.4c-0.2-0.3-1.7-2.3-1.7-4.4c0-2.1,1.1-3.1,1.5-3.5 c0.4-0.4,0.8-0.5,1.1-0.5s0.6,0,0.8,0c0.3,0,0.6-0.1,1,0.7c0.4,0.8,1.2,2.9,1.3,3.1c0.1,0.2,0.2,0.5,0,0.7 c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.6-0.6,0.7c-0.2,0.2-0.4,0.4-0.2,0.9c0.2,0.4,1.1,1.8,2.4,2.9c1.6,1.4,3,1.9,3.4,2.1 c0.4,0.2,0.7,0.2,0.9-0.1c0.2-0.3,1.1-1.2,1.3-1.7c0.3-0.4,0.6-0.4,1-0.2c0.4,0.1,2.5,1.2,2.9,1.4h0c0.4,0.2,0.7,0.3,0.8,0.5 C85.1,33.8,85.1,34.7,84.7,35.7z"
      />
    </g>
    <g>
      <path class="st3" d="M48.9,43.6l-5.3,4" />
      <path class="st3" d="M52.6,39.8c1,1,1,2.7,0,3.8c-1,1-2.7,1-3.8,0c-1-1-1-2.7,0-3.8C49.9,38.8,51.6,38.8,52.6,39.8" />
      <path class="st3" d="M48.9,55.2l-5.3-4" />
      <path class="st3" d="M52.6,55.3c1,1,1,2.7,0,3.8c-1,1-2.7,1-3.8,0c-1-1-1-2.7,0-3.8C49.9,54.2,51.6,54.2,52.6,55.3" />
      <path class="st3" d="M43.6,47.5c1,1,1,2.7,0,3.7c-1,1-2.7,1-3.7,0c-1-1-1-2.7,0-3.7C40.8,46.5,42.5,46.5,43.6,47.5" />
    </g>
  </svg>
</template>
<style>
.st0{fill:none !important;}
.st1{fill:none !important;
  stroke:#000000;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;}
.st2{fill:url(#SVGID_1_);}
.st3{fill:none !important;
stroke:#000000;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;}
</style>
