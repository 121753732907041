<template>
  <div class="flex bg-nav_color text-text_alt_color items-center px-2 border-t border-text_alt_color">
    <div class="p-1">
      <div
        v-if="material.store?.cart_visible && material.store?.price_visible"
        class="bg-nav_color p-2  rounded-full"
        @click="!loading ?emit('open-dialouges'):null"
      >
        <MevcutLoadingIndicator :loading="loading" color="white" class="w-[33px] h-[33px]">
          <v-icon
            v-if="!loading"
            icon="mdi mdi-cart-plus"
            :size="33"
            class="text-text_alt_color"
          />
        </MevcutLoadingIndicator>
      </div>

      <div
        v-else
        class="bg-nav_color p-1 rounded-full"
        @click.stop="emit('open-dialouges')"
      >
        <IconsCartWhatsapp
          class="w-10 h-10"
        />
      </div>
    </div>
    <div class="flex-grow text-center">
      <div
        v-if="material.store?.price_visible"
        :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
        class="mx-2 text-lg font-medium"
      >
        <span>{{ totalPrice }}</span>
      </div>

      <div
        v-if="totalPriceWithOutDiscount && material.store?.price_visible"
        class="leading-none line-through font-medium"
        :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
      >
        {{ totalPriceWithOutDiscount }}
      </div>
    </div>

    <div>
      <MaterialsShowQuantityByStep
        v-if="material.simple_unit_style || !material.unit_information?.length"
        v-model="quantity"
        :size="18"
        :material="material"
      />
      <MaterialsShowQuantityByUnit
        v-else
        v-model="quantity"
        class="pt-1"
        :material="material"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Material } from '~~/composables/useMenuModel'
const props = defineProps<{
  modelValue: number
  totalPrice: String
  totalPriceWithOutDiscount: String
  loading: boolean
  material: Material
}>()

const emit = defineEmits(['update:modelValue', 'open-dialouges'])

const quantity = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<style scoped></style>
