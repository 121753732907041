<template>
  <div
    v-for="definition in allDefinitions"
    :key="`definition-box-${definition.id}`"
    class="w-full mt-2"
  >
    <div class="flex flex-wrap items-center mx-1 text-sm text-mevcut-light">
      <div class="font-semibold leading-1">{{ definition.label }}:</div>
      <div
        v-for="property in definition.propertiesListValues"
        :key="`attribute-box-${property.id}`"
        class="cursor-pointer mx-1"
      >
        <MaterialsShowPropertiesLookupValuesElementValue
          :option-properties-lookup="property.option"
          :is-not-disable="isNotDisable(property)"
          :is-selected-before="checkIsSelectedBefore(property.value_id)"
          @on-tap-option="onTapOption(property)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  Material,
  PropertiesLookupValue,
  Definition
} from '~/composables/useMenuModel'
const { groupBy } = useMenuModel()
const props = withDefaults(
  defineProps<{
    material: Material
  }>(),
  {}
)
function onTapOption(propertyLookupValue: PropertiesLookupValue): void {
  const materialWithOtherOption =
    getMaterialWithOtherOptions(propertyLookupValue)
  if (materialWithOtherOption) {
    emit('update-material', materialWithOtherOption.id)
  }
}
function checkIsSelectedBefore(valueId: number | null): boolean {
  return (
    (
      props.material.propertiesLookupValuesCustom
        ?.propertiesLookupValuesEffectPricesOriginal || []
    ).findIndex((element) => element.value_id === valueId) !== -1
  )
}
const emit = defineEmits(['update-material'])
const allDefinitions = ref<Definition[]>(getAllDefinitions())
/* هذا أهم متحول بالقيم المؤثرة على السعر */
/* يحتوي على قائمة باسماء التصانيف (مثل قياسات- أحجام) مع قيمها */

function getAllDefinitions() {
  const propertiesLookupValuesGroupedByPropertyId = groupBy(
    props.material?.propertiesLookupValuesCustom
      ?.allPropertiesLookupValuesEffectPrices || [],
    (obj) => obj.property_id
  )

  const result: Definition[] = []
  for (const propertyId of propertiesLookupValuesGroupedByPropertyId.keys()) {
    const definitionTemp = getDefintionByPropertyId(
      propertyId,
      props.material?.propertiesLookupValuesCustom
        ?.propertiesLookupValuesEffectPricesOriginal || []
    )
    if (definitionTemp !== null) {
      definitionTemp.propertiesListValues = getValuesProperty(
        propertiesLookupValuesGroupedByPropertyId,
        propertyId
      )
      result.push(definitionTemp)
    }
  }

  return result
}
/* الحصول على قيم التصنيف الأساسي بواسطة property_id */
/* اسم التصنيف يكون مثلا قياسات   */
/* قيم القياسات يكون - صغير - كبير - متوسط وهكذا  */
function getValuesProperty(
  propertiesLookupValuesGroupedByPropertyId: Map<
    number | null,
    PropertiesLookupValue[]
  >,
  propertyId: number | null
): PropertiesLookupValue[] {
  const propertiesList =
    propertiesLookupValuesGroupedByPropertyId.get(propertyId) ?? []

  const propertiesListGroupedByValueId = groupBy(
    propertiesList,
    (obj) => obj.value_id
  )

  let propertiesLookupValuesForEveryPropertyId = Array.from(
    propertiesListGroupedByValueId.values()
  ).map((list) => list[0])

  propertiesLookupValuesForEveryPropertyId = useSortPropertyLookupValues(
    propertiesLookupValuesForEveryPropertyId
  )

  return propertiesLookupValuesForEveryPropertyId
}
/* الحصول على اسم التصنيف الأساسي للقيم بواسطة property_id */
/* اسم التصنيف يكون مثلا قياسات أو ألوان  */
function getDefintionByPropertyId(
  propertyId: number | null,
  propertiesLookupValuesEffectPricesOriginal: PropertiesLookupValue[]
): Definition | null {
  if (propertyId !== null) {
    const propertyLookupValue = propertiesLookupValuesEffectPricesOriginal.find(
      (element) => element.property_id === propertyId
    )
    return propertyLookupValue?.definition ?? null
  }
  return null
}
function compareBetweenOtherOptionMaterialAndSelectedPropertyLookupValue(
  selectedPropertyLookupValueTemp: PropertiesLookupValue[],
  materialOtherOption: PropertiesLookupValue[]
): boolean {
  // فرز القوائم حسب valueId

  selectedPropertyLookupValueTemp = useSortPropertyLookupValues(
    selectedPropertyLookupValueTemp
  )
  materialOtherOption = useSortPropertyLookupValues(materialOtherOption)

  // مقارنة العناصر
  let areListsEqual = true
  if (selectedPropertyLookupValueTemp.length === materialOtherOption.length) {
    for (let i = 0; i < selectedPropertyLookupValueTemp.length; i++) {
      if (
        selectedPropertyLookupValueTemp[i].value_id !==
        materialOtherOption[i].value_id
      ) {
        areListsEqual = false
        break
      }
    }
  } else {
    areListsEqual = false
  }
  return areListsEqual
}
function getMaterialWithOtherOptions(
  valuePropertyLookup: PropertiesLookupValue
) {
  const selectedPropertyLookupValueTemp = [
    ...(props.material?.propertiesLookupValuesCustom
      ?.propertiesLookupValuesEffectPricesOriginal ?? [])
  ]

  selectedPropertyLookupValueTemp
    .filter(
      (element) => element.property_id === valuePropertyLookup.property_id
    )
    .forEach((element) => {
      const index = selectedPropertyLookupValueTemp.indexOf(element)
      if (index !== -1) {
        selectedPropertyLookupValueTemp.splice(index, 1)
      }
    })

  selectedPropertyLookupValueTemp.push(valuePropertyLookup)

  const result = (props.material.otherOptions ?? []).find((element) =>
    compareBetweenOtherOptionMaterialAndSelectedPropertyLookupValue(
      selectedPropertyLookupValueTemp,
      element.material_property_lookup_values || []
    )
  )

  return result
}

/* لكي يكون الخيار متاح يجب
إما أن ينتمي لمادة ظاهرة ومتاحة بالخيارات الأخرى
أو أن لا ينتمي أبدا وهذا يعني أنه ينتمي للمادة الأصلية وبالتالي يكون محدد مسبقاً
 */

function isNotDisable(valuePropertyLookup: PropertiesLookupValue): boolean {
  const materialWithOtherOption =
    getMaterialWithOtherOptions(valuePropertyLookup)
  const isSelected = checkIsSelectedBefore(valuePropertyLookup.value_id)
  return (
    isSelected ||
    (materialWithOtherOption?.visible === true &&
      materialWithOtherOption?.available === true)
  )
}
</script>
