<template>
  <div class="prose">
    <Style>
      ul { list-style-type: disc; margin-top: 1.25em; margin-bottom: 1.25em; }
      :is([dir="ltr"] ul) { padding-right: 0 !important; padding-left: 1.625em !important; }
      :is([dir="rtl"] ul) { padding-left: 0 !important; padding-right: 1.625em !important; }
    </Style>
    <div class="text-text_color" v-html="markdownToHtml"></div>
  </div>
</template>

<script setup lang="ts">
import { marked } from 'marked'

const props = defineProps<{
  content: string
}>()

const markdownToHtml = computed(() => {
  return marked(props.content)
})
</script>
