<template>
  <div
    :key="`attribute-box-${optionPropertiesLookup}`"
    class="cursor-pointer m-1"
    @click="isNotDisable ? emit('on-tap-option') : null"
  >
    <div
      v-if="!optionPropertiesLookup.value?.includes('#')"
      class="border border-text-200 rounded-lg px-2 font-light relative"
      :class="{
        'text-text_alt_color bg-nav_color': isSelectedBefore
      }"
    >
      <div :class="{ 'opacity-30': !isNotDisable }">
        {{ optionPropertiesLookup.value }}
      </div>
      <div
        v-if="!isNotDisable"
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        X
      </div>
    </div>
    <div
      v-else
      class="p-px rounded-full"
      :class="
        !isSelectedBefore
          ? 'border-alt_color border'
          : 'border-nav_color border-2'
      "
    >
      <div class="w-6 h-6 p-px overflow-hidden cursor-pointer relative">
        <div
          :class="{ 'opacity-60': !isNotDisable }"
          class="rounded-full h-full w-full"
          :style="`background-color: ${optionPropertiesLookup.value}`"
        ></div>
        <div
          v-if="!isNotDisable"
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          X
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OptionPropertiesLookup } from '~/composables/useMenuModel'

withDefaults(
  defineProps<{
    optionPropertiesLookup: OptionPropertiesLookup
    isSelectedBefore: boolean
    isNotDisable?: boolean
  }>(),
  {
    isNotDisable: true
  }
)
const emit = defineEmits(['on-tap-option'])
</script>
