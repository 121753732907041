<template>
  <div class="flex justify-between w-full">
    <MevcutDialouge
      width="auto"
      :model-value="modelValue"
      :with-vmodel="true"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #content>
        <div class="mevcut-container w-full bg-bg_alt_color">
          <MevcutLoadingIndicator
            v-if="loading && !material"
            :loading="true"
            color="border-nav_color"
            class="text-nav_color h-36 w-36"
          />
          <MevcutFetchError
            v-else-if="error"
            :message="t('fetch_failed', { data: t('materials') })"
            @click="emit('update:modelValue', false)"
          />

          <MaterialsShowMeta
            v-else-if="material"
            :key="`MaterialsShowMeta${material.id}`"
            class="w-full rounded-md"
            :material="material"
            :is-dialouge="true"
            @added-cart-success="emit('update:modelValue', false)"
            @refresh-page="fetchMaterial"
          />
        </div>
      </template>
    </MevcutDialouge>
  </div>
</template>

<script setup lang="ts">
import type { FetchError } from 'ofetch'
import getAllRoutes from '~/composables/AppApiRoutes'
import type {
  Material,
  PropertiesLookupValue
} from '~~/composables/useMenuModel'
const { materialModel } = useMenuModel()
const emit = defineEmits(['update:modelValue'])
const props = defineProps<{
  materialId: number
  modelValue: boolean
  firstPropertyLookup?: PropertiesLookupValue
}>()

let material = ref<Material | null>(null)
const loading = ref<boolean>(false)
let error = ref<FetchError | null>(null)
const { t } = useI18n()
await fetchMaterial()

async function fetchMaterial(newIdMaterial?: number) {
  const branchId = useBranchId()
  loading.value = true

  const {
    data: materialTemp,
    error: errorTemp,
    pending
  } = await useBasicFetch(getAllRoutes().materialsRoutes.materialShowByServer, {
    query: {
      id: newIdMaterial || props.materialId,
      branch_id: branchId,
      domain: useDomainHeader()
    },

    transform: (data: SuccessResponse<Material>) => {
      return materialModel(data.data)
    }
  })
  error = errorTemp
  loading.value = pending.value
  material = materialTemp
}
</script>
