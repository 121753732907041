<template>
  <div class="shadow-xl p-1 shadow-white/50  rounded-full">
    <button
      :aria-label="t('add_material', { to: t('wishlist') })"
      @click.stop="toggleFavouriteLocal"
    >
      <v-icon
        :icon="
          hasFavoritedAgent ? 'fa: fa-solid fa-heart' : 'fa: fa-regular fa-heart'
        "
        :size="25"
        class="block custom-size-heart-icon text-red-500"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import type { Material } from '~~/composables/useMenuModel'
const props = withDefaults(
  defineProps<{
    material: Material
  }>(),
  {}
)
const { t } = useI18n()
const { toggleValueFavourite } = useCacheSession()
const hasFavoritedAgent = computed(() => {
  return wishlist.value || wishlistToggleByPublicEvent.value
})
const wishlistToggleByPublicEvent = ref<boolean>(false)
const { wishlist, toggleWishlist } = useWishlist(
  props.material.id,
  props.material.has_favorited || props.material.hasFavoritedByAgent
)
const { $emitPublicEvent, $onPublicEvent } = useNuxtApp()

async function toggleFavouriteLocal() {
  if (await toggleWishlist()) {
    toggleValueFavourite(props.material.id, wishlist.value)
    $emitPublicEvent('changeFavouriteValue', {
      materialId: props.material.id,
      hasFavorited: wishlist.value
    })
  }
}
$onPublicEvent('changeFavouriteValue', (valuePublicEvent: any) => {
  if (valuePublicEvent.materialId === props.material.id) {
    wishlistToggleByPublicEvent.value = valuePublicEvent.hasFavorited
  }
})
</script>
